import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {HospitalCareBenefit} from '@peachy/legacy-domain'


const benefitTemplate = HospitalCareBenefit()

export default function HospitalCard() {

    return (
        <BenefitCard header={'Hospital Care'} className={styles.hospital} benefitTemplate={benefitTemplate}>
            <p>Get treatment fast once we know what's wrong.</p>
            <p>We provide cover (up to your chosen annual limit) for:</p>
            <ul>
                <li>Treatment as an in-patient or day-patient in a private hospital or clinic</li>
                <li>Out-patient surgical procedures at a private hospital or clinic</li>
                <li>Specialist consultations & investigations for up to 6 months after discharge if related to your treatment</li>
                <li>Cancer treatment, follow-up outpatient consultations & diagnostic tests – even if the cancer comes back</li>
            </ul>
        </BenefitCard>
    )
}
