import React, {useState} from 'react'
import styles from './HeaderQuote.module.css'
import library from '../../../global-style/library.module.css'
import Expander from '../../../elements/Expander/Expander'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'
import FormattedPrice from '../FormattedPrice/FormattedPrice'
import {PartyPopper} from '@peachy/assets'

// @ts-ignore
import samplePlan from '../../../docs/peachy-sample-plan.pdf'
import SamplePlanLink from '../SamplePlanLink/SamplePlanLink'
import {Quote, toClass} from '@peachy/legacy-domain'
import {useGetPromoIfValidForPrimaryLife} from '../../../hooks/usePromos'
import Modal from '../../../elements/Modal/Modal'
import {classList} from '@peachy/utility-kit-pure'

type HeaderQuoteProps = { show: boolean }

export default function HeaderQuote({show}: HeaderQuoteProps) {

    const quote = useCurrentQuote()
    const quoteService = useQuoteService()

    const hasResponse = !!quote.response?.value

    const quoteClass = toClass(quote.value, Quote)
    const primaryLife = quoteClass.request.getPrimaryLife()

    return (
        <Expander tag={'section'} expanded={hasResponse && show} className={styles.HeaderQuoteSection}>
            <div className={styles.HeaderQuote}>
                <h6>Here's your quote {primaryLife.firstname}:</h6>
                <a id={'accept-quote-header'} onClick={() => quoteService.apply()} className={library.button}>
                    <FormattedPrice notifyCrm/>&nbsp;&#8594;
                </a>

                <PromoBlurb/>

                <p>See what's covered, change your level of cover and add family members below.</p>
                <p><SamplePlanLink/></p>
            </div>
        </Expander>
    )
}

function PromoBlurb() {

    const promo = useGetPromoIfValidForPrimaryLife()

    const [showModal, setShowModal] = useState(false)

    const onDismiss = () => {
        setShowModal(false)
    }

    if (!!promo) {
        const blurb = promo.blurb ? <span>{promo.blurb}</span> : promo.code.toLowerCase() === 'until3' ? until3Blurb :
            <span>Discount applied!</span>
        return <mark>
            <PartyPopper/>
            <div className={styles.HeaderQuotePromo}>
                {blurb}
                {promo.terms && <span onClick={() => setShowModal(true)}><i>Terms & conditions apply &rarr;</i></span>}
            </div>
            <PartyPopper/>

            {showModal && <Modal onDismiss={onDismiss}>
                <section className={styles.HeaderQuoteTermsBreakdown}>
                    <Cancel onDismiss={onDismiss}/>
                    <TermsBreakdown terms={promo.terms}/>
                </section>
            </Modal>}
        </mark>
    } else {
        return null
    }
}

const until3Blurb = <span>But you came here via Until, so you'll get the first three months free!<br/>(Lucky you)</span>


type CancelProps = {
    onDismiss: () => void
}

const Cancel = (props: CancelProps) =>
    <button className={classList('fal fa-times', styles.cancel)} onClick={(e) => {
        props.onDismiss()
    }}>X</button>

type TermsProps = { terms: string }

const TermsBreakdown = (prop: TermsProps) => {
    return (<>
        <h3>Terms & Conditions</h3>
        <p>{prop.terms}</p>
    </>)
}