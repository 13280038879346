import { enumerate } from "./enum-kit"

type MimeTypeString = `${string}/${string}`

export const MIME_HEIC: MimeTypeString = 'image/heic'
export const MIME_JPEG: MimeTypeString = 'image/jpeg'
export const MIME_MP4: MimeTypeString = 'video/mp4'
export const MIME_MSWORD: MimeTypeString = 'application/msword'
export const MIME_MSWORD_XML: MimeTypeString = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const MIME_PDF: MimeTypeString = 'application/pdf'
export const MIME_PNG: MimeTypeString = 'image/png'
export const MIME_QUICKTIME: MimeTypeString = 'video/quicktime'

export type MimeTypeBasePart = 'application' | 'image' | 'video'

export const MimeTypes = enumerate([
    MIME_HEIC,
    MIME_JPEG,
    MIME_MP4,
    MIME_PDF,
    MIME_PNG,
    MIME_QUICKTIME,
    MIME_MSWORD,
    MIME_MSWORD_XML,
])
export type MimeType = keyof typeof MimeTypes


export function getMimeType(uriOrFilenameOrExtension: string) {
    
    let cleaned = uriOrFilenameOrExtension?.trim()?.toLowerCase() ?? ''
    const hasQueryParams = cleaned.indexOf('?') >= 0
    cleaned = hasQueryParams ? cleaned.substring(0, cleaned.indexOf('?')) : cleaned

    if (cleaned) {
        const fileExtension = cleaned.includes('.') ? cleaned.substring(cleaned.lastIndexOf('.') + 1) : cleaned
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
                return MIME_JPEG
            case 'mp4':
                return MIME_MP4
            case 'mov':
                return MIME_QUICKTIME
            case 'pdf':
                return MIME_PDF
            case 'png':
                return MIME_PNG
            case 'heic':
                return MIME_HEIC
            case 'doc':
                return MIME_MSWORD
            case 'docx':
                return MIME_MSWORD_XML
        }    
    }
}

export function getMimeTypeBasePart(mimeType: MimeTypeString) {
    return mimeType?.substring(0, mimeType.indexOf('/')) as MimeTypeBasePart
}

export function getMimeTypeExtensionPart(mimeType: MimeTypeString) {
    return mimeType?.substring(mimeType.indexOf('/') + 1)
}
