import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {TherapiesBenefit} from '@peachy/legacy-domain'


const benefitTemplate = TherapiesBenefit()


export default function TherapiesCard() {

    return (
        <BenefitCard header={'Therapies'} className={styles.therapies} benefitTemplate={benefitTemplate}>
            <p>We all need a little help sometimes – whether it's a pulled hamstring from our daily run to advice about our nutrition</p>
            <p>We provide cover (up to £2.5k) for access to physiotherapy, osteopathy, chiropractic, acupuncture, chiropody/podiatry & nutritional support</p>

        </BenefitCard>
    )
}
