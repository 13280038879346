// package exports here
export {useCookies} from './src/cookies/useCookies'

export * from './src/stage-network/StageNetwork'
        import * as me from '@peachy/utility-kit-browser'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/utility-kit/browser'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/utility-kit-browser'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    