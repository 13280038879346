import { isNotNullish } from "./type-check-kit"
import { Optional } from "./utility-types"

export const sum = <T>(array: T[], prop?: (o: T) => number) => array.reduce((acc, a) => acc + (prop ? prop(a) : +a), 0)

// TODO belong to magneticStrip?
export function minElement<E>(array: E[], metric: (e: E) => number): E {
    let min = Number.MAX_SAFE_INTEGER
    let minItem: E = undefined
    array.forEach((element) => {
        const elementValue = metric(element)
        if (elementValue < min) {
            min = elementValue
            minItem = element
        }
    })
    return minItem
}

export function maxElement<E>(array: E[], metric: (e: E) => number): E {
    let max = Number.MIN_SAFE_INTEGER
    let maxElement: E = undefined
    array.forEach((element) => {
        const elementValue = metric(element)
        if (elementValue > max) {
            max = elementValue
            maxElement = element
        }
    })
    return maxElement
}


export const first = <T>(array: T[]) => (array?.length) ? array[0] : undefined
export const last = <T>(array: T[]) => (array?.length) ? array[array.length - 1] : undefined





export function joinWhereDefined(separator: string, ...items: any[]) {
    return removeUndefinedsFrom(items).join(separator)
}

export function removeUndefinedsFrom(items: any[]) {
    return items.filter(it => !!it)
}

export const unique = <T>(array: T[]): T[] => [...Array.from(new Set(array))]
export const onlyTruthy = <T>(array: T[]): T[] => array.filter(e => !!e)
export const onlyUniqueTruthy = <T>(array: T[]): T[] => unique(onlyTruthy(array))


export function shuffle<T>(array: T[]) {
    const shuffled = [...array]
    const lastIndex = shuffled.length - 1
    for (let i = lastIndex; i > 0 ; i--) {
        const j = Math.round(Math.random() * i)
        const temp = shuffled[i]
        shuffled[i] = shuffled[j]
        shuffled[j] = temp
    }
    return shuffled
}


export function chunk<T>(array: T[], chunkSize: number) {
    const numChunks = Math.ceil(array.length / chunkSize)
    const chunks: T[][] = []

    while (chunks.length < numChunks) {
        const chunkIndex = chunks.length * chunkSize
        chunks.push(array.slice(chunkIndex, chunkIndex + chunkSize))
    }
    return chunks
}

export function mapWhereDefined<T, R>(things: T[], mapFn: (thing: T) => Optional<R>) {
    return things.reduce((mappedThings, thing) => {
        const mapped = mapFn(thing)
        if (isNotNullish(mapped)) {
            mappedThings.push(mapped)
        }
        return mappedThings
    }, [] as R[])
}
