import React, {useState} from 'react'
import styles from './BuildQuote.module.css'
import {RouteStageProps} from '../StageRouter/StageRouter'
import {classList} from '@peachy/utility-kit-pure'
import WhatsNotCovered from './WhatsNotCovered/WhatsNotCovered'
import AnyQuestions from './AnyQuestions/AnyQuestions'
import ConsultationsCard from './BenefitCard/ConsultationsCard'
import HospitalCard from './BenefitCard/HospitalCard'
import RemoteCareCard from './BenefitCard/RemoteCareCard'
import MentalHealthCard from './BenefitCard/MentalHealthCard'
import TherapiesCard from './BenefitCard/TherapiesCard'
import DentalOpticalCard from './BenefitCard/DentalOpticalCard'
import AddFamily from './AddFamily/AddFamily'
import {useScope} from '../../elements/Scope'
import ReactDOM from 'react-dom'
import PeachyLogo from '../../Logo/PeachyLogo'
import StartDate from './StartDate/StartDate'
import {useQuoteService} from '../QuoteController'

import {IpidDoc} from '@peachy/assets'

import Modal from '../../elements/Modal/Modal'
import HeaderQuote from './HeaderQuote/HeaderQuote'
import {LifeTypes, QuoteRequest, toClass} from '@peachy/legacy-domain'
import {QuoteStageName} from '@peachy/quote-client'
import SamplePlanLink from './SamplePlanLink/SamplePlanLink'
import {ROOT_DOMAIN} from '../../App/App'

export type BuildQuoteProps = RouteStageProps<QuoteStageName> & {
    className?: string
}


export default function BuildQuote({className}: BuildQuoteProps) {

    const rootClasses = classList(styles.BuildQuote, className)

    const quoteService = useQuoteService()

    const reEditPrimaryLife = () => {
        const quoteRequest = toClass(quoteService.getCurrentQuote().request.value, QuoteRequest)
        const primaryLife = quoteRequest.getPrimaryLife()
        quoteService.removeLife(primaryLife.id)
        // quoteService.clearQuote()
        quoteService.createLifeFrom(primaryLife)
    }


    return (<>
        <QuoteHeaderNav onX={`https://www.${ROOT_DOMAIN}`} onBack={reEditPrimaryLife}/>


        <main className={rootClasses}>

            <HeaderQuote show={true}/>
            <section className={styles.benefitGroup}>
                <h6>Start date:</h6>
                <StartDate/>
            </section>

            <section className={styles.benefitGroup}>
                <h6>Select at least one:</h6>
                <ConsultationsCard/>
                <HospitalCard/>
            </section>


            <section className={styles.benefitGroup}>
                <LimitInfo/>
            </section>


            <section className={styles.benefitGroup}>
                <h6>Always included:</h6>
                <RemoteCareCard/>
                <MentalHealthCard/>
            </section>
            <section className={styles.benefitGroup}>
                <h6>Super charge your cover:</h6>
                <TherapiesCard/>
                <DentalOpticalCard/>
            </section>
            <section className={styles.benefitGroup}>
                <FamilyInfo/>
                <AddFamily type={LifeTypes.SECONDARY}/>
                <AddFamily type={LifeTypes.DEPENDANT}/>

            </section>
            <section className={styles.benefitGroup}>
                <h6 className={styles.docLink}>
                    <SamplePlanLink/> and other <a href={IpidDoc} target={'_blank'} rel="noreferrer">
                    insurance product information document
                </a>
                </h6>
            </section>
            <WhatsNotCovered/>
            <AnyQuestions/>
        </main>
    </>)
}

function QuoteHeaderNav({onBack, onX}) {

    const quoteNavPortal = useScope<HTMLElement>()

    return quoteNavPortal ? ReactDOM.createPortal(<>
        {onBack &&
        <button className={styles.backButton} onClick={onBack}>
            <i className="fal fa-angle-left"/>
        </button>
        }
        <PeachyLogo/>
        {onX &&
        <a className={styles.exitButton} href={onX}>
            <i className="fal fa-times"/>
        </a>
            // <button className={styles.exitButton} onClick={onX}>
            //     <i className="fal fa-times"/>
            // </button>
        }
    </>, quoteNavPortal) : null
}


function LimitInfo({}) {
    const [showModal, setShowModal] = useState(false)

    const onDismiss = () => {
        setShowModal(false)
    }

    return (<>
        <h6 className={styles.LimitInfo} onClick={() => setShowModal(true)}>
            <span className={styles.docLink}>
                Not sure what limit to choose? Check out our <mark>annual limit info</mark> for examples of treatment prices.
            </span>
        </h6>
        {showModal &&
        <Modal onDismiss={onDismiss}>
            <section className={classList(styles.FamilyInfo, styles.LimitFaq)}>
                <Cancel onDismiss={onDismiss}/>

                <h5>What are annual limits?</h5>


                <p> Depending on how you craft your plan, some benefits
                    may come with annual limits. An annual limit lasts for 12 months from your start date and
                    refreshes every 12 months thereafter – assuming you remain on continuous cover from the
                    start of your plan.</p>

                <h5>What annual limit should I choose?</h5>

                    <p>We are not able to offer advice on what annual limits you
                        should choose when crafting your plan however, we can provide you with information which can
                        help you make a decision.</p>

                <h6>For Consultations & diagnostics</h6>
                <p>Here are some examples of how much private consultations & diagnostic tests can cost:</p>
                <p>
                    <dl>
                        <dt>Initial consultation</dt>
                        <dd>£170 – £280</dd>

                        <dt>Colonoscopy</dt>
                        <dd>£2,065 – £2,885</dd>

                        <dt>Gastroscopy</dt>
                        <dd>£1,655 – £2,570</dd>

                        <dt>MRI scan (1 body)</dt>
                        <dd>£390 - £640</dd>
                    </dl>
                    <cite>(Source: PHIN 2019, Nuffield Healthcare Aug 2021)</cite>
                </p>

                <h6>For Hospital care</h6>
                <p>Here are some examples of how much private medical procedures treatments can cost:</p>
                <p>
                    <dl>
                        <dt>Hip replacement</dt>
                        <dd>£10,195 – £15,625</dd>

                        <dt>Knee replacement</dt>
                        <dd>£10,195 – £16,795</dd>

                        <dt>Slipped disc removal (lower back)</dt>
                        <dd>£6,629 – £14,750</dd>

                        <dt>Knee surgery (ACL repair)</dt>
                        <dd>£6,400</dd>

                        <dt>Gall bladder removal (keyhole surgery)</dt>
                        <dd>£5,195 – £8,010</dd>

                        <dt>Shoulder decompression</dt>
                        <dd>£5,115</dd>

                        <dt>Key arthroscopy</dt>
                        <dd>£3,070 – £4,575</dd>

                        <dt>Spinal injection</dt>
                        <dd>£2,085 – £3,285</dd>

                        <dt>Carpal tunnel release</dt>
                        <dd>£1,945 – £2,720</dd>
                    </dl>
                    <cite>(Source: Nuffield Healthcare Aug 2021)</cite>
                </p>
            </section>
        </Modal>
        }
    </>)
}


function FamilyInfo({}) {
    const [showModal, setShowModal] = useState(false)

    const onDismiss = () => {
        setShowModal(false)
    }


    return (<>
        <h6 onClick={() => setShowModal(true)}>
            <span className={styles.infoIcon}>Add family members</span>
        </h6>
        {showModal &&
        <Modal onDismiss={onDismiss}>
            <section className={styles.FamilyInfo}>
                <Cancel onDismiss={onDismiss}/>
                <p className={styles.advice}>
                    To add your children or partner/spouse to your plan, they must live at the same address as you. If you add a child
                    to your plan who is under 16 you will manage their account through your app, including booking Virtual GP appointments,
                    checking cover and making claims. If you add a child to your plan who is over 16 they will have their own app and
                    will manage their own account. Please note - they will also receive the same benefits as you.
                </p>
            </section>
        </Modal>
        }
    </>)
}


const Cancel = ({onDismiss}) => <button className={classList('fal fa-times', styles.cancel)} onClick={(e) => {
    onDismiss()
}}>X</button>
