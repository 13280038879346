import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {MentalHealthBenefit} from '@peachy/legacy-domain'


const benefitTemplate = MentalHealthBenefit()

export default function MentalHealthCard() {

    return (
        <BenefitCard header={'Mental health'} canDeselect={false} className={styles.mental} benefitTemplate={benefitTemplate}>
            <p>We've got your back when you need someone to talk to, and if you need it we bring more intensive support & treatment</p>
            <p>We provide cover for mental health treatment as an in-patient or day-patient (up to £20k per year) & for out-patient care (up to £1.5k per year)</p>
        </BenefitCard>
    )
}
