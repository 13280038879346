import {enumerate} from '@peachy/utility-kit-pure'
import {GenderType} from '../people'
import {BenefitType, BenefitTypes} from '../plans'

export type LegacyQuoteRequest = {
    quoteRequestId: string;
    version?: number;
    type?: PolicyType;
    groupSize?: number;
    primaryIndividual?: IndividualQuoteRequest;
    secondaryIndividual?: IndividualQuoteRequest;
    children?: IndividualQuoteRequest[];
    groupedIndividuals?: IndividualQuoteRequest[];
    includeSecondaryIndividual?: boolean;
    annualBilling?: boolean;
};

export type IndividualQuoteRequest = {
    lifeId: string;
    birthdate: string;
    includeBreakdown: boolean;
    coverStartDate: string;
    renewalPeriodMonths?: number;
    gender: GenderType;
    region: LegacyQuoteRegion;
    address: {
        display: string;
        postcode: string;
        addressComponents: string[];
        location: {
            lat: number;
            lng: number;
        };
    };
    requiredBenefits: BenefitType[];
    benefitOverrides?: BenefitOverrides;
    benefitLimits: BenefitValues;
    overallExcess?: number;
    applyExcessAtBenefitLevel?: boolean;
    benefitExcesses?: BenefitValues;
};

export type BenefitValues = {
    [_ in BenefitType]?: number;
};

export type BenefitOverrides = {
    [_ in BenefitType]?: {
        coverStartDate?: string;
        renewalPeriodMonths?: number;
    };
};

export type LegacyQuoteResponse = {
    quoteRequestId: string;
    quoteResponseId: string;
    timestamp: number;
    primaryIndividual: IndividualQuoteResponse;
    secondaryIndividual: IndividualQuoteResponse;
    children: IndividualQuoteResponse[];
    groupedIndividuals?: IndividualQuoteResponse[];
    totalPolicyPrice: number;
};

export type IndividualQuoteResponse = {
    lifeId: string;
    totalPlanPrice: number;
    pricesByBenefit: LegacyBenefitPriceBreakdown;
    benefitPriceBreakdown: PlanPricingBreakdowns;
};

export type PlanPricingBreakdowns = {
    a_baseRiskCost: LegacyBenefitPriceBreakdown;
    b_adjustedForCorrelation: LegacyBenefitPriceBreakdown;
    c_adjustedForContingency: LegacyBenefitPriceBreakdown;
    d_adjustedForLossRatio: LegacyBenefitPriceBreakdown;
    e_adjustedForIpt: LegacyBenefitPriceBreakdown;
    f_adjustedForDiscounts: LegacyBenefitPriceBreakdown;
    g_adjustedForPriceResolution: LegacyBenefitPriceBreakdown;
};

export type LegacyBenefitPriceBreakdown = {
    [_ in BenefitType | PolicyDiscount]: number;
};

export const PolicyTypes = enumerate([
    'INDIVIDUAL',
    'SME',
    'VOLUNTARY'
] as const)

export type PolicyType = keyof typeof PolicyTypes;

export const PolicyDiscounts = enumerate([
    'HOSPITAL_CARE_EXCESS_DISCOUNT',
    'CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT',
    'THERAPIES_EXCESS_DISCOUNT',
    'OVERALL_EXCESS_DISCOUNT',
    'SME_GROUP_DISCOUNT',
    'VOLUNTARY_DISCOUNT',
    'ANNUAL_BILLING_DISCOUNT'
] as const)

export type PolicyDiscount = keyof typeof PolicyDiscounts;

export const excessDiscountNameFor = (benefitType: BenefitType) => {
    switch(benefitType) {
        case BenefitTypes.HOSPITAL_CARE: return PolicyDiscounts.HOSPITAL_CARE_EXCESS_DISCOUNT
        case BenefitTypes.CONSULTATIONS_DIAGNOSTICS: return PolicyDiscounts.CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT
        case BenefitTypes.THERAPIES: return PolicyDiscounts.THERAPIES_EXCESS_DISCOUNT
        default: return null
    }

}

export const LegacyQuoteRegions = enumerate([
    'EAST',
    'LONDON',
    'MIDLANDS',
    'NORTHEAST',
    'NORTHWEST',
    'SCOTLAND',
    'SOUTHEAST',
    'SOUTHWEST',
    'WALES'
] as const)

export type LegacyQuoteRegion = keyof typeof LegacyQuoteRegions;
