import React, {useEffect, useState} from 'react'
import {classList, dateFromIso, formatIsoDateTimeOnly} from '@peachy/utility-kit-pure'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'
import Modal from '../../../elements/Modal/Modal'
import {isBefore, startOfToday} from 'date-fns'
import styles from './StartDateModal.module.css'

export default function StartDateModal() {
    const quote = useCurrentQuote()

    const startDate = dateFromIso(quote.request.startDate.value)
    const quoteService = useQuoteService()

    const [showModal, setShowModal] = useState(false)

    const onDismiss = () => {
        setShowModal(false)
    }

    useEffect(() => {
        if (isBefore(startDate, startOfToday())) {
            setShowModal(true)
            quote.request.startDate.set(formatIsoDateTimeOnly(startOfToday()))
            quoteService.requestQuote()
        }
    }, [])

    return (<>
        {showModal &&
            <Modal onDismiss={onDismiss}>
                <section className={styles.StartDateModal}>
                    <button className={classList('fal fa-times', styles.cancel)} onClick={onDismiss}></button>
                    <h3>Start Date Change</h3>
                    <p>As you started this quote previously, we have updated your policy start date to be today.</p>
                    <p>This may have changed the price, please check the price still meets your needs</p>
                </section>
            </Modal>
        }
    </>)
}
