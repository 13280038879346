import 'reflect-metadata'

export * from './src/event/event-def'
export * from './src/event/event-kit'

export * from './src/stripe/stripe-kit'
export * from './src/stripe/models/stripe-event-types'
export * from './src/stripe/models/stripe-object-types'

export * from './src/domain/some-types'

export * from './src/domain/models/Company'
export * from './src/domain/models/User'
export * from './src/domain/models/Life'
export * from './src/domain/models/Policy'
export * from './src/domain/models/Subscription'
export * from './src/domain/models/Plan'
export * from './src/domain/models/Excess'
export * from './src/domain/models/PaymentDetails'
export * from './src/domain/models/LifeBenefit'

export * from './src/domain/models/PlanBenefit'
export * from './src/domain/models/Account'
export * from './src/domain/models/Address/Address'
export * from './src/domain/models/Payment'
export * from './src/domain/models/Promo'
export * from './src/domain/models/Premium'

export * from './src/domain/models/DocGenTicket'
export * from './src/domain/models/DocData/PolicyDocData'
export * from './src/domain/models/DocData/PlanChange'
export * from './src/domain/models/DocData/CompanyPolicyDocData'
export * from './src/domain/models/DocData/MemberPolicyDocData'
export * from './src/domain/models/EmailData/PolicyDocEmailData'
export * from './src/domain/models/EmailData/PolicyDocChangesEmailData'
export * from './src/domain/models/EmailData/CompanyPolicyDocEmailData'
export * from './src/domain/models/EmailData/IndividualRenewalEmailData'
export * from './src/domain/models/EmailData/EmailData'
export * from './src/domain/models/EmailData/InviteSmeAdminEmailData'
export * from './src/domain/models/EmailData/InviteUserEmailData'
export * from './src/domain/models/EmailData/VerifyUserEmailData'
export * from './src/domain/models/EmailData/ResetPasswordEmailData'
export * from './src/domain/models/EmailData/CompanyPolicyDocChangesEmailData'
export * from './src/domain/models/EmailData/UserCancellationEmailData'
export * from './src/domain/models/EmailData/CancellationEmailData'


export * from './src/domain/models/GeoLocation'


export * from './src/event/models/SubscriptionCreated'
export * from './src/event/models/PolicyDocRequest'

export * from './src/event/models/PolicyDocSent'
export * from './src/event/models/PolicyReposRequest'
export * from './src/event/models/PolicyReposCreated'

export * from './src/event/models/ChangedLife'
export * from './src/event/models/MrChangedLife'

export * from './src/domain/models/CancellationType'


export * from './src/event/models/kyc/IdCheckRequest'
export * from './src/event/models/kyc/IdCheckEvent'
export * from './src/event/models/kyc/IdCheckFlagged'
export * from './src/event/models/kyc/IdCheckFailed'
export * from './src/event/models/kyc/IdCheckPassed'

export * from './src/event/models/kyc/IndividualSanctionsCheckRequest'
export * from './src/event/models/kyc/SanctionsCheckEvent'
export * from './src/event/models/kyc/SanctionsCheckFlagged'
export * from './src/event/models/kyc/SanctionsCheckFailed'
export * from './src/event/models/kyc/SanctionsCheckPassed'

export * from './src/event/models/kyc/company/OwnerSanctionsCheckRequest'

export * from './src/event/models/payments/PaymentReceived'
export * from './src/event/models/payments/PaymentFailed'
export * from './src/event/models/payments/PaymentRefunded'
export * from './src/event/models/RawStripeEvent'
export * from './src/event/models/CancelSubscriptionRequest'
export * from './src/event/models/CancelPolicyRequest'
export * from './src/event/models/CancelLifeRequest'
export * from './src/event/models/SubscriptionCancelled'

export * from './src/event/models/IntercomEventRequest'

export * from './src/domain/validation/validation-core'
export * from './src/domain/validation/validation-kit'

export * from './src/api/api-definition'
export * from './src/api-gateway/defineApiGateway'

export * from './src/domain/models/Address/uk-regions'
export * from './src/domain/models/Address/uk-county-region'

export * from './src/dto/AccountSubscription'


export * from './src/event/models/docs/activation/IndividualPolicyDocSendRequest'
export * from './src/event/models/docs/activation/CompanyPolicyDocSendRequest'
export * from './src/event/models/docs/activation/MemberPolicyDocSendRequest'

export * from './src/event/models/docs/alteration/IndividualPolicyDocAlterationSendRequest'
export * from './src/event/models/docs/alteration/CompanyPolicyAlterationDocSendRequest'
export * from './src/event/models/docs/alteration/MemberPolicyDocAlterationSendRequest'
export * from './src/event/models/docs/reissue/SendIndividualPolicyDocReIssueRequest'
export * from './src/event/models/docs/cancellation/IndividualCancellationEmailSendRequest'
export * from './src/event/models/docs/cancellation/CompanyCancellationEmailSendRequest'
export * from './src/event/models/docs/cancellation/MemberCancellationEmailSendRequest'

export * from './src/event/models/life-repo/LifeRepoUpsertRequest'
export * from './src/event/models/life-repo/LifeRepoUpserted'

export * from './src/event/models/docs/EmailSendStatus'


export * from './src/event/models/notifications/notification-kit'
export * from './src/event/models/notifications/SubscriptionActivatedNotification'
export * from './src/event/models/notifications/SubscriptionAlteredNotification'
export * from './src/event/models/notifications/SubscriptionCancelledNotification'
export * from './src/event/models/notifications/LifeActivatedNotification'
export * from './src/event/models/notifications/LifeAlteredNotification'
export * from './src/event/models/notifications/LifeCancelledNotification'

export * from './src/domain/models/memorepo/MrAccount'
export * from './src/domain/models/memorepo/MrLife'
export * from './src/domain/models/memorepo/MrSubscription'
export * from './src/domain/models/memorepo/MrLifeBenefit'
export * from './src/domain/models/memorepo/MrPaymentEvent'
export * from './src/domain/models/memorepo/MrPaymentPeriod'
export * from './src/domain/models/memorepo/MrPolicy'
export * from './src/domain/models/memorepo/MrPlan'
export * from './src/domain/models/memorepo/MrPlanBenefit'
export * from './src/domain/models/memorepo/LifecycleStatus'

export * from './src/domain/models/memorepo/MrSubscriptionQuoteRequest'
export * from './src/domain/models/memorepo/MrSubscriptionQuoteResponse'

export * from './src/domain/models/memorepo/statuses'


export * from './src/domain/mapping/mapToLegacyAccount'

export * from './src/event/models/interfaces'

        import * as me from '@peachy/core-domain-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/core-domain/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/core-domain-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    