import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {DentalOpticalBenefit} from '@peachy/legacy-domain'


const benefitTemplate = DentalOpticalBenefit()

export default function DentalOpticalCard () {

    return (
        <BenefitCard header={'Dental & optical care'} className={styles.dentalOptical} benefitTemplate={benefitTemplate}>
            <p>Our teeth & eyes need tender loving care too!</p>
            <p>We provide cashback for dental care (up to £200 per year) & optical care (£100 per year)</p>
        </BenefitCard>
    )
}
